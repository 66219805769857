<template>
    <div>
        <div class="form-search-box flex space">
            <div class="flex alcenter">
                <a-button @click="addAccount" type="primary">添加账号</a-button>
            </div>
            <div>
                <a-form layout="inline">
                    <a-form-item label="日期范围">
                        <a-range-picker @change="changeOperateTime" v-model="search.data" valueFormat="YYYY-MM-DD"
                            style="width: 200px;" />
                    </a-form-item>
                    <a-form-item label="搜索">
                        <a-input v-model="search.keyword" style="width: 120px"></a-input>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="searchAct" type="primary">查询</a-button>
                        <a-button @click="cancelAct" class="ml10">取消</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>
        <div class="mt20 pd20 bg-w">
            <div class="xhm-table-gray mt20">
                <a-table rowKey="log_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
                    :data-source="list" :loading="loading" :rowKey="(row, index) => { return index; }">
                    <!-- 账号状态 -->
                    <template slot="status" slot-scope="status,record,index">
                        <a-tag v-if="status == 1" color="green">正常</a-tag>
                        <a-tag v-else color="red">禁用</a-tag>
                    </template>
                    <!-- 账号权限 -->
                    <template slot="role" slot-scope="role,record,index">
                        <a-tag v-if="role == 1" color="cyan">管理员</a-tag>
                        <a-tag v-if="role == 0" color="green">账号</a-tag>
                        <a-tag v-if="role == 6" color="green">售前</a-tag>
                        <a-tag v-if="role == 7" color="pink">设计师</a-tag>
                        <a-tag v-if="role == 8" color="blue">平台管理员</a-tag>
                        <a-tag v-if="role == 9" color="purple">平台超级管理员</a-tag>
                    </template>
                    <template slot="action" slot-scope="record,index">
                        <a-button type="link" @click="editAccount(record)">修改</a-button>
                    </template>
                </a-table>
            </div>
        </div>

        <a-modal width="400px" :title="title" :visible="show" @cancel="show = false" @ok="save">
            <a-form-model :model="accountData" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item label="姓名">
                    <a-input v-model="accountData.name" style="width: 200px;" />
                </a-form-model-item>
                <a-form-model-item label="手机号">
                    <a-input v-model="accountData.mobile" style="width: 200px;" />
                </a-form-model-item>
                <a-form-model-item label="密码">
                    <a-input v-model="accountData.pwd" style="width: 200px;" />
                </a-form-model-item>
                <a-form-model-item label="权限">
                    <a-select v-model="accountData.role" style="width: 200px;">
                        <a-select-option :value="0">账号</a-select-option>
                        <a-select-option :value="1">管理员</a-select-option>

                        <!-- <a-select-option :value="2">超级创始人</a-select-option> -->
                        <!-- <a-select-option :value="3">代理</a-select-option> -->

                        <a-select-option :value="6">售前</a-select-option>
                        <a-select-option :value="7">设计师</a-select-option>
                        <!-- <a-select-option :value="8">平台管理员</a-select-option>
                        <a-select-option :value="9">平台超级管理员</a-select-option> -->
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="状态">
                    <a-radio-group v-model="accountData.status" button-style="solid">
                        <a-radio-button :value="1">正常</a-radio-button>
                        <a-radio-button :value="0">禁用</a-radio-button>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="可建账号量">
                    <a-input v-model="accountData.num" type="number" style="width: 200px;" />
                </a-form-model-item>

                <a-form-model-item label="备注">
                    <a-textarea v-model="accountData.notes" style="width: 200px;" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import { apiAccountList, apiAccountSave } from '../../utils/js/api';
import orderList from '../custom/order.vue';
export default {
    components: {
        orderList
    },
    data() {
        return {
            loading: false,
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
            search: {
                keyword: '',
                data: ['', '']
            },
            show: false,
            title: '添加账号',
            data: {
                account_id: 0,
                name: '',
                mobile: '',
                pwd: '',
                role: 0,
                num: 1,
                status: 1,
            },
            accountData: {},
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
            columns: [
                { title: '账号id', dataIndex: 'account_id', align: 'center' },
                { title: '名字', dataIndex: 'name', align: 'center' },
                { title: '手机号', dataIndex: 'mobile', align: 'center' },
                { title: '状态', dataIndex: 'status', align: 'center', scopedSlots: { customRender: 'status' } },
                { title: '权限', dataIndex: 'role', align: 'center', scopedSlots: { customRender: 'role' } },
                { title: '备注', dataIndex: 'notes', align: 'center' },
                { title: '操作', key: 'action', align: 'center', scopedSlots: { customRender: 'action' } },
            ],
            list: [],
            orderData: {}
        }
    },
    created() {
        this.getList();
    },
    methods: {
        addAccount() {
            this.title = '添加账号';
            this.show = true;
            this.accountData = this.data;
        },
        editAccount(detail) {
            this.show = true;
            detail.pwd = '';
            this.accountData = detail;
        },
        getList() {
            if (this.loading == true) return;
            this.loading = true;
            apiAccountList({
                limit: this.pagination.pageSize,
                page: this.pagination.current,
                keyword: this.search.keyword,
                start_time: this.search.data[0],
                end_time: this.search.data[1]
            }).then(res => {
                this.pagination.total = res.total;
                this.list = res.list;
                this.loading = false;
            }).catch(res => {
                console.log(res);
                this.loading = false;
            })
        },
        save() {//提交
            apiAccountSave(this.accountData).then(res => {
                this.show = false;
                this.$message.success('成功');
                this.getList();
            })
        },
        changeOperateTime() {
            this.search.data = value;
        },
        handleTableChange(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getList();
        },
        searchAct() {
            this.getList();
        },
        cancelAct() {
            this.search = {
                keyword: '',
                data: ['', ''],
                designer_id: 0
            };
            this.getList();
        }
    }
}
</script>

<style></style>
